window.addEventListener("DOMContentLoaded", function () {
  Spree.setChFlowCookie();

  document.addEventListener("turbolinks:load", function () {
    console.log("turbolinks:load");
    dataLayer.push({
      event: "optimize.pageload",
      page: {
        url: location.pathname,
        title: document.title,
        referrer: document.referrer,
        hash: location.hash,
      },
    });
  });
});
